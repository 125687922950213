<template>
  <div class="terms">
    <HeaderSection></HeaderSection>
    <PageWrapper>
      <MarkdownRenderer :file="file"></MarkdownRenderer>
    </PageWrapper>
    <FooterSection></FooterSection>
  </div>
</template>

<script>
import MarkdownRenderer from '@/components/MarkdownRenderer/MarkdownRenderer.vue'
import HeaderSection from '@/components/HeaderSection/HeaderSection.vue'
import FooterSection from '@/components/FooterSection/FooterSection.vue'
import PageWrapper from '@/components/PageWrapper/PageWrapper.vue'

export default {
  computed: {
    file() {
      return `/docs/terms-and-conditions_${this.$i18n.locale}.md`
    },
  },
  components: {
    HeaderSection,
    FooterSection,
    MarkdownRenderer,
    PageWrapper,
  },
}
</script>

<style lang="scss">
@import "@/css/base.scss";

.md-container {
  padding: 2rem;
}

body {
  background-color: white;
}
</style>
